import { H2, Paragraph } from "~/components/ui/typography";
import { Pricing as PricingComponent } from "~/components/pricing";
import type { Pricing as PricingType } from "~/types/sanity-schema";
import type { AdditionalSectionData } from "~/components/renderSections";
import { BlockContent } from "~/components/block-content";

type Props = PricingType & {
	pricingRelatedData: AdditionalSectionData["pricingRelatedData"];
};

export function Pricing({
	pricingRelatedData,
	title,
	variant,
	defaultCloud,
	tagline,
	subtitle,
}: Props) {
	if (!pricingRelatedData) {
		return null;
	}

	return (
		<>
			{title ? (
				<div className="mx-auto mb-7 max-w-content text-center">
					{tagline ? (
						<Paragraph
							size="overline"
							className="mb-5"
							color="tagline"
						>
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2 className="mb-5">{title}</H2> : null}
					{subtitle ? (
						typeof subtitle === "string" ? (
							<Paragraph>{subtitle}</Paragraph>
						) : (
							<BlockContent value={subtitle} />
						)
					) : null}
				</div>
			) : null}
			<PricingComponent
				variant={variant}
				pricingRelatedData={pricingRelatedData}
				defaultCloud={defaultCloud}
			/>
		</>
	);
}
