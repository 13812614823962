import { TextMedia as TextVideoComponent } from "../text-media";
import { asLink } from "~/utils/sanity-helpers";
import type { TextVideo as TextVideoType } from "~/types/sanity-schema";
import { useYoutubeTracking } from "../youtube-with-tracking";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import { Icon } from "~/components/ui/icons";

export function TextVideo({
	ytId,
	ytImg,
	tagline,
	title,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	text,
	variant,
	mediaWidth = "default",
}: TextVideoType) {
	const ytIdString = ytId || "";
	const taglineText = tagline || "";

	const { handleOnStateChange } = useYoutubeTracking(ytIdString);

	const imgProps = getImageProps(
		getImageBuilder(ytImg, { alt: getImageAltProp(ytImg) }),
		{
			widths: [400, 800],
			sizes: ["(min-width:1024px) 50vw", "100vw"],
		}
	);

	return (
		<TextVideoComponent
			onVideoStateChange={handleOnStateChange}
			variant={variant}
			tagline={taglineText}
			title={title}
			ytId={ytIdString}
			image={imgProps}
			mediaWidth={mediaWidth}
			text={text}
			primaryCTA={{
				title: primaryCTA?.title,
				caption: primaryCTA?.caption,
				url: asLink(primaryCTA),
				trackingPosition: "text video",
				iconLeft: primaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			secondaryCTA={{
				title: secondaryCTA?.title,
				caption: secondaryCTA?.caption,
				url: asLink(secondaryCTA),
				trackingPosition: "text video",
				iconLeft: secondaryCTA?.isPdf ? <Icon name="file" /> : null,
			}}
			tertiaryCTA={{
				title: tertiaryCTA?.title,
				caption: tertiaryCTA?.caption,
				url: asLink(tertiaryCTA),
				trackingPosition: "text video",
				iconLeft: tertiaryCTA?.isPdf ? (
					<Icon name="file" color="primary" />
				) : null,
			}}
			imagePosition="top"
		/>
	);
}
