import clsx from "clsx";
import type { Cta } from "~/types/index";
import { H2, Paragraph } from "~/components/ui/typography";
import { CtaButtonGroup } from "~/components/ui/button";
import { Youtube } from "~/components/youtube";
import { BlockContent } from "~/components/block-content";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { SimplePortableText } from "~/types/sanity-schema";
import type { YouTubeProps } from "react-youtube";

interface Props {
	image?: ImageProps;
	mobileImage?: ImageProps;
	mobileImageSetting?: "default" | "hide" | "custom";
	ytId?: string;
	tagline?: string;
	title?: string;
	text?: SimplePortableText;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	variant?: "mediaLeft" | "mediaRight" | "mediaCenter";
	mediaWidth?: string;
	imagePosition?: "top" | "center" | "bottom";
	onVideoStateChange?: YouTubeProps["onStateChange"];
	onVideoPlay?: YouTubeProps["onPlay"];
}

export function TextMedia({
	image,
	mobileImage,
	mobileImageSetting = "default",
	ytId,
	tagline,
	title,
	text,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	variant = "mediaLeft",
	mediaWidth = "default",
	imagePosition = "center",
	onVideoPlay,
	onVideoStateChange,
}: Props) {
	const variants = {
		mediaLeft: "md:flex-row",
		mediaRight: "md:flex-row-reverse",
		mediaCenter: "!flex-col-reverse text-center",
	};

	const imagePositions = {
		top: "items-start",
		center: "items-center",
		bottom: "items-end",
	};

	const mediaLeftRightWidths: { [key: string]: string } = {
		default: "flex-1",
		sm: "md:basis-1/2 lg:basis-1/3",
		lg: "md:basis-1/2 lg:basis-2/3",
	};

	const mediaCenterWidths: { [key: string]: string } = {
		default: "md:w-1/2 lg:w-2/3",
		sm: "md:w-1/2",
		lg: "w-full",
	};

	return (
		<div
			className={clsx(
				"flex flex-col gap-layout4",
				variants[variant],
				imagePositions[imagePosition]
			)}
		>
			<div
				className={clsx("mx-auto", {
					[mediaLeftRightWidths[mediaWidth] ||
					mediaLeftRightWidths.default]: variant != "mediaCenter",
					[mediaCenterWidths[mediaWidth] ||
					mediaCenterWidths.default]: variant === "mediaCenter",
					"lg:self-center": variant === "mediaCenter",
				})}
			>
				{ytId ? (
					<Youtube
						onStateChange={onVideoStateChange}
						onPlay={onVideoPlay}
						id={ytId}
						children={title}
						image={image}
					/>
				) : image?.src ? (
					<>
						<Image
							className={clsx("mx-auto rounded-lg", {
								"hidden md:block":
									mobileImageSetting !== "default",
							})}
							{...image}
						/>
						{mobileImage?.src && mobileImageSetting !== "hide" ? (
							<Image
								className="mx-auto rounded-lg md:hidden"
								{...mobileImage}
							/>
						) : null}
					</>
				) : null}
			</div>
			<div
				className={clsx("flex flex-1 flex-col justify-center", {
					"mx-auto max-w-content": variant === "mediaCenter",
				})}
			>
				{tagline ? (
					<Paragraph size="overline" className="mb-5" color="tagline">
						{tagline}
					</Paragraph>
				) : null}
				{title ? <H2 className="mb-5">{title}</H2> : null}
				{text ? <BlockContent value={text} /> : null}
				{(primaryCTA && primaryCTA.title) ||
				(secondaryCTA && secondaryCTA.title) ||
				(tertiaryCTA && tertiaryCTA.title) ? (
					<div
						className={clsx(
							"mt-5 flex flex-col flex-wrap gap-5 md:flex-row",
							{
								"justify-center": variant === "mediaCenter",
							}
						)}
					>
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
							tertiaryCTA={tertiaryCTA}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}
