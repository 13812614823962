import type { ServiceItem } from "../service-list";
import { ServiceList as ServiceListComponent } from "../service-list";
import { getImageBuilder, getImageProps } from "~/utils/images";
import { asLink, asText } from "~/utils/sanity-helpers";
import type { ServiceList as ServiceListType } from "~/types/sanity-schema";
import { useMemo } from "react";
import { DEFAULT_LOGO_HEIGHT } from "~/utils/media-height";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";

export function ServiceList({
	title,
	subtitle,
	tagline,
	services,
	hostProviders,
	tools,
	integrations,
	compliance,
	identity,
	managed,
	securityNetwork,
}: ServiceListType) {
	const { t } = useSharedContent(Dictionary.COMMON);
	const tagText = {
		services: t("openSourceServices"),
		hostProviders: t("cloudProviders"),
		tools: t("tooling"),
		integrations: t("integrations"),
		compliance: t("compliance"),
		identity: t("identity"),
		managed: t("managed"),
		securityNetwork: t("securityNetwork"),
	};

	const items: Record<
		| "services"
		| "hostProviders"
		| "tools"
		| "integrations"
		| "compliance"
		| "identity"
		| "managed"
		| "securityNetwork",
		Array<ServiceItem>
	> = useMemo(() => {
		const serviceItems = services
			? services.map((item) => ({
					group: item?.group,
					title: item?.title,
					tagline: item?.tagline,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})
					),
					cta: {
						url: asLink(item?.cta),
					},
			  }))
			: [];
		const hostProviderItems = hostProviders
			? hostProviders.map((item) => ({
					icon: getImageProps(
						getImageBuilder(item?.logo?.logoImage, {
							alt: `${item?.name} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const toolItems = tools
			? tools.map((item) => ({
					icon: getImageProps(
						getImageBuilder(item?.logo?.logoImage, {
							alt: `${item?.name} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const integrationItems = integrations
			? integrations.map((item) => ({
					title: item?.title,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const securityNetworkItems = securityNetwork
			? securityNetwork.map((item) => ({
					title: item?.title,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const identityItems = identity
			? identity.map((item) => ({
					title: item?.title,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const managedItems = managed
			? managed.map((item) => ({
					title: item?.title,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		const complianceItems = compliance
			? compliance.map((item) => ({
					title: item?.title,
					icon: getImageProps(
						getImageBuilder(item?.icon, {
							alt: `${item?.title} logo`,
						})?.height(DEFAULT_LOGO_HEIGHT)
					),
			  }))
			: [];

		return {
			services: serviceItems,
			hostProviders: hostProviderItems,
			tools: toolItems,
			integrations: integrationItems,
			securityNetwork: securityNetworkItems,
			identity: identityItems,
			managed: managedItems,
			compliance: complianceItems,
		};
	}, [
		hostProviders,
		services,
		tools,
		integrations,
		securityNetwork,
		identity,
		managed,
		compliance,
	]);

	const hasPlatformItems =
		(securityNetwork && securityNetwork.length > 0) ||
		(identity && identity.length > 0) ||
		(managed && managed.length > 0) ||
		(compliance && compliance.length > 0);

	return (
		<ServiceListComponent
			items={items}
			title={asText(title)}
			subtitle={subtitle}
			tagline={tagline}
			tagText={tagText}
			variant={hasPlatformItems ? "platform" : "default"}
		/>
	);
}
