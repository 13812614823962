import { LocationList as LocationListComponent } from "../location-list";
import type { Location } from "../location-list";
import { asText } from "~/utils/sanity-helpers";
import type { LocationList as LocationListType } from "~/types/sanity-schema";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";

export function LocationList({ title, subtitle, locations }: LocationListType) {
	const { t } = useSharedContent(Dictionary.COMMON);

	const locationItems = locations
		? locations.map<Location>((item) => ({
				title: item?.title,
				address: item?.address,
				link: {
					title: t("getDirection"),
					url: item?.link,
				},
				icon: item?.icon,
		  }))
		: [];

	return (
		<LocationListComponent
			title={asText(title)}
			subtitle={subtitle}
			locationItems={locationItems}
		/>
	);
}
