import clsx from "clsx";
import { H2, Paragraph } from "~/components/ui/typography";
import { Link } from "~/components/ui/link";
import type { Cta } from "~/types";
import { BlockContent } from "~/components/block-content";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { Tag } from "~/components/ui/tag";
import type { SimplePortableText } from "~/types/sanity-schema";
import { useLocale, useSharedContent } from "~/hooks/localization";
import { getLocalePath, Dictionary } from "~/utils/language";
import groupBy from "lodash/groupBy";

interface ServiceProps {
	service: ServiceItem;
	iconHeight: number;
	iconStyle?: string;
	category?: string;
}

function Service({
	service,
	iconHeight,
	iconStyle,
	category,
	...restProps
}: ServiceProps) {
	const locale = useLocale();

	const content = (
		<figure className="flex items-center gap-3">
			<Image
				style={{
					height: `${iconHeight}px`,
				}}
				className={iconStyle}
				{...service.icon}
			/>
			<figcaption>
				{service.title && (
					<Paragraph size="body-xsmall">
						{category === "services" && "Aiven for "}{" "}
						{service.title}
					</Paragraph>
				)}
			</figcaption>
		</figure>
	);

	return service.cta?.url ? (
		<Link
			to={getLocalePath(service.cta.url, locale)}
			trackingText={service.title}
			trackingPosition="service list"
			className="flex gap-5 hover:underline"
		>
			{content}
		</Link>
	) : (
		<>{content}</>
	);
}

export type ServiceItem = {
	title?: string;
	name?: string;
	tagline?: string;
	group?: string;
	icon?: ImageProps;
	cta?: Cta;
};

const GRID_AREA = {
	securityNetwork: "xl:row-start-1 xl:col-start-4 xl:row-end-5 xl:col-end-5",
	identity: "xl:row-start-5 xl:col-start-4 xl:row-end-9 xl:col-end-5",
	services: "xl:row-start-1 xl:col-start-1 xl:row-end-4 xl:col-end-4",
	hostProviders: " xl:row-start-4 xl:col-start-1 xl:row-end-5 xl:col-end-4",
	tools: "xl:row-start-5 xl:col-start-1 xl:row-end-6 xl:col-end-4",
	integrations: "xl:row-start-6 xl:col-start-1 xl:row-end-9 xl:col-end-4",
	managed: "xl:row-start-1 xl:col-start-5 xl:row-end-5 xl:col-end-6",
	compliance: "xl:row-start-5 xl:col-start-5 xl:row-end-9 xl:col-end-6",
};

const ICON_HEIGHT = {
	securityNetwork: 40,
	identity: 24,
	services: 24,
	hostProviders: 24,
	tools: 24,
	integrations: 40,
	managed: 40,
	compliance: 40,
};

export type ServiceListCategory =
	| "services"
	| "hostProviders"
	| "tools"
	| "integrations"
	| "compliance"
	| "identity"
	| "managed"
	| "securityNetwork";

export interface ServiceListProps {
	title: string;
	subtitle?: SimplePortableText;
	tagline?: string;
	items: Record<ServiceListCategory, ServiceItem[]>;
	tagText: Record<ServiceListCategory, string>;
	variant: "default" | "platform";
}

export function ServiceList({
	tagline,
	title,
	subtitle,
	items,
	tagText,
	variant = "platform",
}: ServiceListProps) {
	const { t } = useSharedContent(Dictionary.COMMON);

	return (
		<>
			{(tagline || title || subtitle) && (
				<div className="mx-auto mb-9 max-w-content text-center">
					{tagline && (
						<Paragraph
							size="overline"
							className="mb-5"
							color="tagline"
						>
							{tagline}
						</Paragraph>
					)}
					{title && <H2 className="mb-5">{title}</H2>}
					{subtitle && <BlockContent value={subtitle} />}
				</div>
			)}
			<div className="dark relative z-10 ml-6 mr-6 rounded bg-secondary-60 p-3 text-center">
				<Paragraph
					size="body-large"
					color="primary"
					fontWeight="font-medium"
				>
					Unified Platform
				</Paragraph>
			</div>
			<div
				className={clsx(
					"-mt-6 flex flex-col gap-3 rounded-2xl bg-secondary-5 px-6 pb-6 pt-9",
					{
						"xl:grid-rows-8 xl:grid xl:grid-cols-5":
							variant === "platform",
					}
				)}
			>
				{Object.entries(items).map((iterator) => {
					// have to cast here since it loses its typing through Object.entries
					const [category, categoryItems] = iterator as [
						ServiceListCategory,
						ServiceItem[]
					];

					const rowItems =
						category === "securityNetwork" ||
						category === "identity" ||
						category === "managed" ||
						category === "compliance";

					const renderServiceItems = (group: string) => {
						const serviceItems = groupBy(
							categoryItems,
							(item) => item.group
						);

						if (!serviceItems[group]) {
							return null;
						}

						return (
							<div className="flex-1">
								<div className="dark relative mb-6 inline-flex items-center rounded-l bg-primary-70 px-3 py-2 max-sm:rounded-r sm:w-[90%]">
									<Paragraph
										size="body-small"
										color="primary"
										fontWeight="font-medium"
									>
										{t(group)}
									</Paragraph>
									<div className="absolute right-0 top-1/2 h-0 w-0 -translate-y-1/2 translate-x-full transform border-t-[16px] border-b-[16px] border-l-[16px] border-t-transparent border-b-transparent border-l-primary-70 max-sm:hidden"></div>
								</div>
								<div className="flex flex-col gap-5">
									{serviceItems[group].map((elm, idx) => {
										return serviceItems[group].length ? (
											<Service
												key={idx}
												service={elm}
												iconHeight={24}
												category="services"
											/>
										) : null;
									})}
								</div>
							</div>
						);
					};

					return (
						categoryItems.length > 0 && (
							<div
								className={clsx("bg-primary rounded p-6", {
									[GRID_AREA[category]]:
										variant === "platform",
								})}
								key={category}
							>
								{category !== "services" ? (
									<div className="dark mb-6">
										<Tag bgColor="bg-secondary-90">
											{tagText[category]}
										</Tag>
									</div>
								) : null}
								<div
									key={category}
									className={clsx({
										"grid gap-x-4 gap-y-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7":
											category === "integrations",
										"flex flex-col flex-wrap gap-5 xl:justify-between":
											category !== "integrations",
										"md:justify-between":
											category === "hostProviders" ||
											category === "tools",
										"sm:flex-row": !rowItems,
										"sm:flex-row xl:flex-col": rowItems,
									})}
								>
									{category !== "services" ? (
										<>
											{categoryItems.map((elm, idx) => (
												<div
													className={clsx({
														"lg:max-w-[14.28%]":
															category ===
																"hostProviders" ||
															category ===
																"tools", // max 7 logos
														"xl:text-center [&>figure]:xl:flex-col":
															category ===
															"integrations",
													})}
													key={idx}
												>
													<Service
														service={elm}
														iconHeight={
															ICON_HEIGHT[
																category
															]
														}
														iconStyle={clsx({
															"p-3 border border-stroke rounded":
																category ===
																"integrations",
														})}
														category={category}
													/>
												</div>
											))}
										</>
									) : (
										<>
											{renderServiceItems("stream")}
											{renderServiceItems("store")}
											{renderServiceItems("serve")}
										</>
									)}
								</div>
							</div>
						)
					);
				})}
			</div>
		</>
	);
}
