import clsx from "clsx";
import { CtaButtonGroup } from "./ui/button";
import { Container } from "./ui/container";
import { Link, ConditionalLink } from "./ui/link";
import useScrollSpy from "~/hooks/useScrollSpy";
import type { Cta } from "~/types";
import { Paragraph } from "./ui/typography";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { DEFAULT_SERVICE_ICON_HEIGHT } from "~/utils/media-height";

export type ScrollSpyNavItem = {
	title: string;
	sectionId: string;
};

interface ScrollSpyNavigationProps {
	logoImgProps?: ImageProps;
	logoLink?: Cta;
	navItems: Array<ScrollSpyNavItem>;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
}

export function ScrollSpyNavigation({
	navItems,
	primaryCTA,
	secondaryCTA,
	logoImgProps,
	logoLink,
}: ScrollSpyNavigationProps) {
	const sectionIds = navItems.map((item) => item.sectionId);
	const hasLogoLink = Boolean(logoLink?.url);

	const activeSectionId = useScrollSpy({
		sectionIds: sectionIds,
		offsetPx: -150,
	});

	return (
		<div className="bg-primary py-5">
			<Container noPadding className="xxl:px-6 flex items-center">
				{/* This empty div makes the nav center in the page. By setting this div to flex-1, it grows as much as the cta section */}
				<div className="flex-1">
					<ConditionalLink
						condition={hasLogoLink}
						to={logoLink?.url || ""}
						trackingText={logoLink?.title}
						trackingPosition="scrollspy"
						className={clsx("flex items-center gap-5", {
							"decoration-theme-primary underline-offset-8 hover:underline":
								hasLogoLink,
						})}
					>
						{logoImgProps?.src ? (
							<Image
								loading="eager"
								height={DEFAULT_SERVICE_ICON_HEIGHT}
								width={DEFAULT_SERVICE_ICON_HEIGHT}
								{...logoImgProps}
							/>
						) : null}
						{logoLink?.title ? (
							<Paragraph
								as="span"
								size="body-small"
								fontWeight="font-medium"
							>
								{logoLink.title}
							</Paragraph>
						) : null}
					</ConditionalLink>
				</div>
				<div className="flex justify-center gap-2">
					{navItems.map((item) => {
						const active = item.sectionId === activeSectionId;
						return (
							<Link
								key={item.sectionId}
								className={clsx(
									"px-3 py-4 decoration-theme-primary underline-offset-8 hover:underline",
									{ underline: active }
								)}
								to={`#${item.sectionId}`}
								trackingText={item.title}
								trackingPosition="scrollspy"
							>
								<Paragraph
									as="span"
									size="body-small"
									fontWeight="font-medium"
								>
									{item.title}
								</Paragraph>
							</Link>
						);
					})}
				</div>
				<div className="flex flex-1 flex-row-reverse justify-start gap-3">
					<CtaButtonGroup
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
						size="dense"
					/>
				</div>
			</Container>
		</div>
	);
}
