import clsx from "clsx";
import { Tab as TabComponent } from "../tab";
import type { Tab as TabType } from "~/types/sanity-schema";
import type { TabItem } from "../tab";
import { asText } from "~/utils/sanity-helpers";
import RenderSections from "../renderSections";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/utils/images";
import { H2, Paragraph } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import { DEFAULT_LOGO_HEIGHT } from "~/utils/media-height";

export function Tab({
	align,
	tagline,
	title,
	subtitle,
	items,
	variant,
}: TabType) {
	if (!items || items.length === 0) {
		return null;
	}

	const tabItems: Array<TabItem> = items.map((item) => {
		return {
			id: asText(item.id),
			label: asText(item.title),
			image: getImageProps(
				getImageBuilder(item?.image, {
					alt: getImageAltProp(item?.image, {
						fallback: `${item?.title} logo`,
					}),
				})?.height(
					item?.imageHeight ? item?.imageHeight : DEFAULT_LOGO_HEIGHT
				)
			),
			imageHeight: item?.imageHeight,
			content: (
				<RenderSections
					sections={item.content?.map((content) => ({
						...content,
						noSpaceLeft: true,
						noSpaceRight: true,
					}))}
					compact={true}
				/>
			),
		};
	});

	return (
		<>
			{title || subtitle ? (
				<div
					className={clsx("mb-8 max-w-content", {
						"mx-auto text-center": align === "center",
					})}
				>
					{tagline ? (
						<Paragraph
							size="overline"
							className="mb-5"
							color="tagline"
						>
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2>{title}</H2> : null}
					{subtitle ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
				</div>
			) : null}
			<TabComponent
				items={tabItems}
				variant={variant ? variant : "default"}
			/>
		</>
	);
}
