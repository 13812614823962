import React from "react";
import { Paragraph, Title } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";
import { AnimatedLoading } from "./animated-loading";
import type { SimplePortableText } from "~/types/sanity-schema";
import clsx from "clsx";

interface ArcadeProps {
	className?: string;
	title?: string;
	id: string;
	minHeight?: number;
}

export const ArcadeEmbedded = React.memo(function Arcade({
	className,
	title,
	id,
	minHeight = 50,
}: ArcadeProps) {
	const [isLoading, setLoading] = React.useState<boolean>(true);

	// Hide loading after 500ms
	React.useEffect(() => {
		const timeoutId = window.setTimeout(() => {
			setLoading(false);
		}, 500);

		return () => {
			window.clearInterval(timeoutId);
		};
	}, []);

	const handleIframeLoaded = () => {
		setLoading(false);
	};

	return (
		<div
			className={clsx("relative", className)}
			style={{
				paddingBottom: `calc(${minHeight}% + 41px)`,
			}}
		>
			{isLoading ? (
				<AnimatedLoading className="p-6" />
			) : (
				<iframe
					title={title}
					src={`https://demo.arcade.software/${id}?embed`}
					width="100%"
					allowFullScreen
					className="absolute"
					height="100%"
					loading="lazy"
					onLoad={handleIframeLoaded}
				/>
			)}
		</div>
	);
});

interface headerProps {
	className?: string;
	tagline?: string;
	title?: string;
	subtitle?: SimplePortableText;
}

export function Header({ tagline, title, subtitle, className }: headerProps) {
	return (
		<div className={clsx(className)}>
			{tagline ? (
				<Paragraph size="overline" className="mb-5" color="tagline">
					{tagline}
				</Paragraph>
			) : null}
			{title ? (
				<Title
					className="mb-5"
					size="heading-2"
					fontWeight="font-semibold"
				>
					{title}
				</Title>
			) : null}
			{subtitle ? <BlockContent value={subtitle} /> : null}
		</div>
	);
}
