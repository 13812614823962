import { IframeGroup as IframeComponent } from "../iframe";
import type { Iframe as IframeType } from "~/types/sanity-schema";
import { asText } from "~/utils/sanity-helpers";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";

export function Iframe({
	headerPosition,
	align,
	tagline,
	title,
	titleAsH1,
	subtitle,
	disclaimer,
	qualifiedExperienceLink,
	image,
	iframeTitle,
	iframeSource,
	iframeHeight,
	iframeFullWidth,
	backgroundColor,
	isPardotForm,
	pardotFormType,
}: Omit<IframeType, "_type">) {
	const mainImage = getImageProps(
		getImageBuilder(image, { alt: getImageAltProp(image) }),
		{
			widths: [400, 600, 800],
			sizes: ["(min-width:1024px) 50vw", "100vw"],
		}
	);

	return (
		<IframeComponent
			headerPosition={headerPosition}
			align={align}
			tagline={tagline}
			title={title}
			titleAsH1={titleAsH1}
			subtitle={subtitle}
			qualifiedExperienceLink={qualifiedExperienceLink}
			image={mainImage}
			iframeTitle={asText(iframeTitle)}
			iframeSource={asText(iframeSource)}
			iframeHeight={iframeHeight}
			iframeFullWidth={iframeFullWidth}
			iframeBgColor={backgroundColor ? backgroundColor : "#ffffff"}
			disclaimer={disclaimer}
			isPardotForm={isPardotForm}
			pardotFormType={pardotFormType}
		/>
	);
}
