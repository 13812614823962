import clsx from "clsx";
import { H6 } from "~/components/ui/typography";
import type { Cta } from "~/types";
import { CtaButtonGroup } from "~/components/ui/button";
import { BlockContent } from "~/components/block-content";
import { ArrowLink } from "~/components/ui/arrow-button";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { SimplePortableText } from "~/types/sanity-schema";
import { CardListLayout } from "~/components/layouts/cardlist-layout";
import { IconLink } from "~/components/ui/icon-link";

interface IconItemProps {
	icon: IconItemType;
	iconSize?: number;
}

function IconItem({ icon, iconSize = 24 }: IconItemProps) {
	return (
		<div className="flex flex-row items-start gap-5">
			{icon.imageProps ? (
				<Image
					className="w-full"
					style={{ maxWidth: `${iconSize}px` }}
					{...icon.imageProps}
				/>
			) : null}
			<div className="flex h-full max-w-content flex-col gap-3">
				{icon.title ? <H6 as="h3">{icon.title}</H6> : null}
				{icon.subtitle ? <BlockContent value={icon.subtitle} /> : null}
				{icon.cta.title && icon.cta.url ? (
					icon.cta.iconLeft ? (
						<IconLink
							className="font-medium"
							to={icon.cta.url}
							iconLeft={icon.cta.iconLeft}
						>
							{icon.cta.title}
						</IconLink>
					) : (
						<ArrowLink to={icon.cta.url}>
							{icon.cta.title}
						</ArrowLink>
					)
				) : null}
			</div>
		</div>
	);
}

export type IconItemType = {
	title?: string;
	subtitle?: SimplePortableText;
	imageProps: ImageProps;
	cta: Cta;
};

interface IconListProps {
	headerPosition?: "default" | "left";
	tagline?: string;
	subtitle?: SimplePortableText;
	title: string;
	itemsPerRow?: number;
	iconItems: IconItemType[];
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	iconSize?: number;
}

export function IconList({
	headerPosition = "left",
	tagline,
	title,
	subtitle,
	itemsPerRow,
	iconItems,
	primaryCTA,
	secondaryCTA,
	iconSize,
}: IconListProps) {
	return (
		<>
			<CardListLayout headerPosition={headerPosition}>
				{headerPosition === "left" ? (
					<CardListLayout.Header
						title={title}
						subtitle={subtitle}
						tagline={tagline}
						isNarrow={iconItems.length > 1}
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
					/>
				) : (
					<CardListLayout.Header
						title={title}
						subtitle={subtitle}
						tagline={tagline}
						isNarrow={iconItems.length > 1}
					/>
				)}
				<CardListLayout.Content>
					<div
						className={clsx(
							{
								"grid grid-cols-1 gap-6 md:grid-cols-2":
									iconItems.length > 1,
							},
							{
								"md:gap-7": headerPosition === "default",
							},
							{
								"md:gap-9": headerPosition === "left",
							},
							{
								"max-w-content lg:mx-auto lg:grid-cols-2":
									itemsPerRow === 2 ||
									headerPosition === "left",
							},
							{
								"lg:grid-cols-3": itemsPerRow === 3,
							},
							{
								"lg:grid-cols-4": itemsPerRow === 4,
							}
						)}
					>
						{iconItems.map((icon, index) => (
							<IconItem
								key={index}
								icon={icon}
								iconSize={iconSize}
							/>
						))}
					</div>
				</CardListLayout.Content>
			</CardListLayout>
			{headerPosition === "default" &&
			(primaryCTA?.title || secondaryCTA?.title) ? (
				<div className="mt-8 flex justify-center gap-5">
					<CtaButtonGroup
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
					/>
				</div>
			) : null}
		</>
	);
}
