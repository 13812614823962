import clsx from "clsx";
import { Paragraph } from "~/components/ui/typography";
import type { Cta } from "~/types";
import { Card } from "./ui/card";
import { ArrowLink } from "~/components/ui/arrow-button";
import type { ImageProps } from "./ui/image";
import type { SimplePortableText } from "~/types/sanity-schema";
import { CardListLayout } from "~/components/layouts/cardlist-layout";
import { Icon } from "~/components/ui/icons";
import { IconLink } from "~/components/ui/icon-link";

export type CardItem = {
	image?: ImageProps;
	title: string;
	subtitle?: SimplePortableText;
	date?: string;
	tagline?: string;
	cta?: Cta;
	variant?: "default" | "icon-card";
	borderTopColor?: string;
};

interface Props {
	tagline?: string;
	subtitle?: SimplePortableText;
	title: string;
	itemsPerRow: number;
	items: Array<CardItem>;
	backgroundColor?: string;
	align: "center" | "left";
	headerPosition?: "default" | "left" | "right";
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	compact?: boolean;
}

export function CardList({
	tagline,
	title,
	subtitle,
	itemsPerRow,
	items,
	align,
	headerPosition = "default",
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	compact = false,
}: Props) {
	// If item length is 1 or 2, we use flex layout so we can center the items
	// else use grid layout
	const shouldUseGridLayout = items.length >= itemsPerRow;

	const isVertical = headerPosition === "default" && itemsPerRow === 1;

	return (
		<>
			<CardListLayout headerPosition={headerPosition} align={align}>
				<CardListLayout.Header
					title={title}
					subtitle={subtitle}
					tagline={tagline}
					isNarrow={items.length > 1}
					primaryCTA={
						headerPosition !== "default" ? primaryCTA : undefined
					}
					secondaryCTA={
						headerPosition !== "default" ? secondaryCTA : undefined
					}
					tertiaryCTA={
						headerPosition !== "default" ? tertiaryCTA : undefined
					}
				/>
				<CardListLayout.Content>
					<div
						className={clsx(
							{
								"justify-center": align === "center",
							},
							{
								"flex flex-col gap-6 md:flex-row":
									!shouldUseGridLayout,
							},
							{
								"grid grid-cols-1 gap-6 md:grid-cols-2":
									shouldUseGridLayout,
							},
							{
								"md:!grid-cols-1": itemsPerRow === 1,
							},
							{
								"lg:grid-cols-2": itemsPerRow === 2,
							},
							{
								"lg:grid-cols-3": itemsPerRow === 3,
							},
							{
								"lg:grid-cols-4": itemsPerRow === 4,
							}
						)}
					>
						{items.map((item, index) => {
							const isIconCard = item.variant === "icon-card";
							const hasContent =
								item.title || item.subtitle || item.tagline;

							return (
								<article
									key={index}
									className={clsx("max-w-auto flex-1", {
										"lg:max-w-[450px]": !isVertical,
									})}
								>
									<Card
										borderTop={isIconCard}
										borderTopColor={item.borderTopColor}
										href={item.cta?.url ?? ""}
										className={clsx({
											"md:flex-row md:items-center":
												isVertical,
										})}
									>
										{item.image?.src &&
										!isIconCard &&
										!compact ? (
											isVertical ? (
												<Card.Cover
													imgProps={item.image}
													className="block sm:hidden lg:block lg:h-full lg:basis-1/2 xl:basis-2/5"
													imageClassName="lg:object-contain xl:object-cover"
												/>
											) : (
												<Card.Cover
													imgProps={item.image}
												/>
											)
										) : null}
										<Card.Content
											className={clsx({
												"!px-5": hasContent,
												"!p-0": !hasContent,
												"md:basis-2/3": isVertical,
											})}
										>
											{item.image?.src && isIconCard ? (
												<Card.Icon
													imgProps={item.image}
												/>
											) : null}
											{item.tagline ? (
												<Card.ChipText>
													{item.tagline}
												</Card.ChipText>
											) : null}
											<Card.Title>
												{item.title || (
													<span className="sr-only">
														{item.cta?.title}
													</span>
												)}
											</Card.Title>
											{item.subtitle && !compact ? (
												<Card.Subtitle>
													{item.subtitle}
												</Card.Subtitle>
											) : null}
											{item.date ? (
												<div className="mt-3 flex items-center gap-3">
													<Icon
														name="playOutline"
														color="primary"
													/>
													<Paragraph size="body-small">
														<span
															suppressHydrationWarning
														>
															{item.date}
														</span>
													</Paragraph>
												</div>
											) : null}
											{isVertical &&
											item.cta &&
											item.cta.url &&
											!compact ? (
												<ArrowLink
													to={item.cta.url}
													className="mt-5"
												>
													{item.cta.title}
												</ArrowLink>
											) : null}
										</Card.Content>
										{!isVertical && !compact ? (
											<Card.Extra
												className={clsx("!px-5", {
													"!pt-5": !hasContent,
												})}
											>
												{item.cta && item.cta.url ? (
													item.cta.iconLeft ? (
														<IconLink
															className="font-medium"
															to={item.cta.url}
															iconLeft={
																item.cta
																	.iconLeft
															}
														>
															{item.cta.title}
														</IconLink>
													) : (
														<ArrowLink
															to={item.cta.url}
														>
															{item.cta.title}
														</ArrowLink>
													)
												) : null}
											</Card.Extra>
										) : null}
									</Card>
								</article>
							);
						})}
					</div>
				</CardListLayout.Content>
				{headerPosition === "default" ? (
					<CardListLayout.Footer
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
						tertiaryCTA={tertiaryCTA}
					/>
				) : (
					<></>
				)}
			</CardListLayout>
		</>
	);
}
