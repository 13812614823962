import React from "react";
import Carousel from "../carousel/carousel";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import { Image } from "~/components/ui/image";
import { H3, Paragraph } from "../ui/typography";
import { ArrowLink } from "../ui/arrow-button";
import clsx from "clsx";
import type { CarouselSlider } from "~/types/sanity-schema";
import { asLink } from "~/utils/sanity-helpers";
import { BlockContent } from "../block-content";

export function ImageLinkSlider({ items = [] }: CarouselSlider) {
	return items ? (
		<div className="max-w-full lg:mx-9 xl:mx-7">
			<Carousel
				autoplay={true}
				autoplaySpeed={5000}
				arrows={true}
				dots={true}
				fade={true}
				dotsClass={"carousel-dot-container"}
				appendDots={(dots) => <div>{dots}</div>}
			>
				{items.map((slide, index) => {
					const style = slide.backgroundColor
						? {
								background: slide.backgroundColor,
						  }
						: {};
					return (
						<div key={index} className="flex h-full">
							<div
								style={style}
								className=" flex h-full flex-col justify-start gap-7 rounded-t-2xl px-layout2 pb-layout5 pt-layout4 md:rounded-t-3xl md:px-layout3 lg:gap-9 lg:px-layout5 xl:flex-row xl:px-layout6"
							>
								<div className="flex-initial xl:flex-1">
									<Image
										className="rounded-lg"
										{...getImageProps(
											getImageBuilder(slide.image, {
												alt: getImageAltProp(
													slide.image
												),
											}),
											{
												widths: [
													400, 600, 800, 1000, 1300,
												],
												sizes: [
													"(min-width:1024px) 50vw",
													"100vw",
												],
											}
										)}
									/>
								</div>

								<div
									className={clsx(
										{ dark: Boolean(slide.darkTheme) },
										"mb-5 flex-none sm:mb-0 xl:min-w-[350px] xl:max-w-[33%]"
									)}
								>
									<H3 className="mb-5 md:mb-6">
										{slide.title}
									</H3>
									{slide.subtitle ? (
										<div className="mb-5">
											{typeof slide.subtitle ===
											"string" ? (
												<Paragraph>
													{slide.subtitle}
												</Paragraph>
											) : (
												<BlockContent
													value={slide.subtitle}
												/>
											)}
										</div>
									) : null}
									{slide.ctaLink ? (
										<ArrowLink
											to={asLink(slide.ctaLink) || ""}
										>
											{slide.ctaLink.title}
										</ArrowLink>
									) : null}
								</div>
							</div>
						</div>
					);
				})}
			</Carousel>
		</div>
	) : null;
}
